import {useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import "./RestaurantNumberEdit.scss";
import axios from "axios";
import {endpoint} from "../../../endpoint/endpoint";

export interface HeaderInfo {
  _id: string;
  restaurantNumber: string;
}

export interface NumberProps {
  header_info: HeaderInfo;
  onNumberChange: (header_info: HeaderInfo) => void;
}

const RestaurantNumberEdit = ({header_info, onNumberChange}: NumberProps) => {
  const [number, setNumber] = useState(
    header_info?.restaurantNumber ?? "000000000"
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endpoint()}/admin/edit/header`,
        {_id: header_info?._id ?? null, restaurantNumber: number},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        onNumberChange({
          _id: header_info?._id ?? response.data.header_info._id,
          restaurantNumber: number,
        });
      } else {
        setNumber(header_info.restaurantNumber);
      }
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  const isValidNumber = number.length >= 9 && number.length <= 12;

  return (
    <form onSubmit={handleSubmit} className="number-form">
      <TextField
        label="Restaurant Number"
        value={number}
        onChange={(e) => setNumber(e.target.value)}
        required
        error={!isValidNumber}
        helperText={
          !isValidNumber && "Number must be between 9 and 12 characters"
        }
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isValidNumber}
      >
        Update Number
      </Button>
    </form>
  );
};

export default RestaurantNumberEdit;
