import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import axios from "axios";
import {IconButton} from "@mui/material";
import CloseCircleOutline from "@mui/icons-material/CloseTwoTone";

import "./Items.scss";
import {endpoint} from "../../../endpoint/endpoint";
import Loader from "../../Loader/Loader";
import {Category} from "../../AdminComponent/CategoryAddEdit/CategoryAddEdit";
import {IItem} from "../../AdminComponent/ItemAddEdit/ItemAddEdit";
import Item from "./Item/Item";
import {MyOrderInterface} from "../MyOrder/MyOrder";

interface ItemsProps {
  category: Category | null;
  myOrder: MyOrderInterface;
  lang: string;
  closeModal: () => void;
  onItemsFetched: (items: IItem[]) => void;
  itemsAddToOrder: (item: IItem) => void;
  itemsRemoveFromOrder: (item: IItem) => void;
}

const Items: React.FC<ItemsProps> = ({
  category,
  myOrder,
  lang,
  closeModal,
  onItemsFetched,
  itemsAddToOrder,
  itemsRemoveFromOrder,
}) => {
  const [items, setItems] = useState<IItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (category?.items && category?.items.length > 0) {
      setItems(category.items);
    } else if (category) {
      setIsLoading(true);
      axios
        .get(`${endpoint()}/items/${category._id}`)
        .then((response: any) => {
          setItems(response.data.items);
          setIsLoading(false);
          onItemsFetched(response.data.items);
        })
        .catch((error: any) => {
          console.error("There was an error!", error);
        });
    }
  }, [category, onItemsFetched]);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled =
        scrollRef.current?.scrollTop && scrollRef.current.scrollTop > 100;
      setIsScrolled(!!isScrolled);
    };

    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollRef.current) {
          scrollRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [scrollRef.current]);

  const onAddToOrderReceived = (item: IItem) => {
    itemsAddToOrder(item);
  };

  const onItemRemovedFromOrder = (item: IItem) => {
    itemsRemoveFromOrder(item);
  };

  return (
    <Modal
      className={`items-modal${isScrolled ? " compact" : ""}`}
      isOpen={!!category}
      onRequestClose={closeModal}
      contentLabel="Items Modal"
      ariaHideApp={false}
      style={{
        content: {
          position: "absolute",
          top: "10%",
          left: "0",
          right: "0",
          bottom: "15%",
          width: "calc(100% - 50px)",
          height: "fit-content",
          maxHeight: "80%",
          maxWidth: "850px",
          borderRadius: "25px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px",
          justifyContent: "center",
          margin: "0 auto",
        },
      }}
    >
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <>
          <div className="category-header">
            <div style={{width: "100%", textAlign: "right"}}>
              <IconButton onClick={closeModal}>
                <CloseCircleOutline />
              </IconButton>
            </div>
            {lang === "mk" ? (
              <h2 className="category-title">{category?.nameMk}</h2>
            ) : (
              <h2 className="category-title">{category?.nameEn}</h2>
            )}
          </div>
          <div className="items" ref={scrollRef}>
            {items
              .sort((a: IItem, b: IItem) => {
                let aValue = a.itemNumber == null ? Infinity : a.itemNumber;
                let bValue = b.itemNumber == null ? Infinity : b.itemNumber;
                return aValue - bValue;
              })
              .map((item, index) => (
                <Item
                  item={item}
                  lang={lang}
                  key={index}
                  inBasket={item._id && myOrder[item._id] ? true : false}
                  onAddToOrder={onAddToOrderReceived}
                  onRemoveFromOrder={onItemRemovedFromOrder}
                />
              ))}
          </div>
        </>
      )}
    </Modal>
  );
};

export default Items;
