import {useState} from "react";
import {
  Card,
  CardContent,
  Typography,
  Modal,
  TextField,
  Button,
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  InputLabel,
} from "@mui/material";

import "./CategoryAddEdit.scss";
import ItemCard, {IItem} from "../ItemAddEdit/ItemAddEdit";
import axios from "axios";
import {endpoint} from "../../../endpoint/endpoint";
import {IGroup} from "../AdminPanel";

export interface CategoryAddEditProps {
  groups: IGroup[];
  categories: Category[];
  onCategoryChange: (category: Category) => void;
  onCategoryAdd: (category: Category) => void;
  onCategoryDelete: (category: Category) => void;
  onItemChange: (item: IItem) => void;
  onItemDelete: (item: IItem) => void;
  onGroupChange: (group: IGroup) => void;
}

export interface Category {
  _id?: string;
  categoryNumber?: number;
  nameMk: string;
  nameEn: string;
  imageUrl: string;
  type: string;
  items?: IItem[];
  group?: IGroup;
}

export const categoryTypes = ["food", "drinks"];

const CategoryAddEdit: React.FC<CategoryAddEditProps> = ({
  groups,
  categories,
  onCategoryChange,
  onCategoryAdd,
  onCategoryDelete,
  onItemChange,
  onItemDelete,
  onGroupChange,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [newCategory, setNewCategory] = useState<Category>({
    categoryNumber: 0,
    nameMk: "",
    nameEn: "",
    imageUrl: "https://drive.google.com/thumbnail?id=",
    type: "",
    group: {_id: "", groupName: ""},
  });
  const [openNewItemModal, setOpenNewItemModal] = useState(false);
  const [newItem, setNewItem] = useState<IItem>({
    itemNumber: 0,
    nameMk: "",
    nameEn: "",
    price: 0,
    imageUrl: "",
    ingredientsMk: "",
    ingredientsEn: "",
  });
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [chosenGroup, setChosenGroup] = useState<IGroup | null>(null);
  const [selectedGroupFilter, setSelectedGroupFilter] = useState<string>("all");

  const handleOpenModal = (group: IGroup) => {
    setChosenGroup(group);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSetMainGroup = async () => {
    if (chosenGroup?._id)
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${endpoint()}/admin/edit/group`,
          {_id: chosenGroup?._id},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          onGroupChange(response.data.editedGroup);
          handleClose();
        } else {
          console.error("Failed setting group as main.");
        }
      } catch (e) {
        console.error(e, "Failed setting group as main.");
      }
    handleCloseModal();
  };

  const handleOpen = (category: Category) => {
    setSelectedCategory(category);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCategoryChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    const {name, value} = event.target;
    setSelectedCategory((prevState) => ({
      ...(prevState as Category),
      [name]:
        name === "group" ? groups.find((group) => group._id === value) : value,
    }));
  };

  const onCategoryChanged = async () => {
    try {
      const token = localStorage.getItem("token");
      const sentCategory = selectedCategory;
      const categoryItems = selectedCategory?.items ?? [];
      delete sentCategory?.items;
      const response = await axios.post(
        `${endpoint()}/admin/edit/category`,
        {editedCategory: sentCategory},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 && selectedCategory) {
        selectedCategory["items"] = categoryItems;
        onCategoryChange(selectedCategory);
        handleClose();
      } else {
        console.error(
          "Error saving new category please make sure that you are using correct information!"
        );
      }
    } catch (e) {
      console.error("ERROR: ", e);
    }
  };

  const onCategoryDeleted = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${endpoint()}/admin/delete/category/${selectedCategory?._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 && selectedCategory) {
        onCategoryDelete(selectedCategory);
        handleClose();
      } else {
        console.error(
          "Error saving new category please make sure that you are using correct information!"
        );
      }
    } catch (e) {
      console.error("ERROR: ", e);
    }
  };

  const handleNewCategoryTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewCategory({...newCategory, [event.target.name]: event.target.value});
  };

  const handleNewCategorySelectChange = (event: SelectChangeEvent<string>) => {
    setNewCategory({
      ...newCategory,
      [event.target.name]: event.target.value as string,
    });
  };

  const handleGroupSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedGroup = groups.find(
      (group) => group._id === event.target.value
    );
    setNewCategory({
      ...newCategory,
      group: {
        _id: event.target.value as string,
        groupName: selectedGroup?.groupName || "",
        isMainGroup: selectedGroup?.isMainGroup || false,
      },
    });
  };

  const handleAddCategory = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endpoint()}/admin/save/category`,
        {newCategory},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        response.data.newCategory.items = [];
        onCategoryAdd(response.data.newCategory);
        setNewCategory({
          categoryNumber: 0,
          nameMk: "",
          nameEn: "",
          imageUrl: "",
          type: "",
        });
      } else {
        console.error(
          "Error saving new category please make sure that you are using correct information!"
        );
      }
    } catch (e) {
      console.error("ERROR: ", e);
    }
  };

  const onItemChanged = (item: IItem) => {
    onItemChange(item);
  };

  const onItemDeleted = (item: IItem) => {
    onItemDelete(item);
  };

  const handleClickOpen = (categoryId: string) => {
    setCategoryId(categoryId);
    setOpenNewItemModal(true);
  };

  const handleCloseNewItemModal = () => {
    setOpenNewItemModal(false);
  };

  const handleInputChange = (event: {target: {name: any; value: any}}) => {
    setNewItem({...newItem, [event.target.name]: event.target.value});
  };

  const handleAddItem = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endpoint()}/admin/save/item`,
        {newItem: {...newItem, categoryId: categoryId}},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        onItemChange(response.data.newItem);
        handleCloseNewItemModal();
      } else {
        console.error("The item update failed! Check the data you enter!!!");
        handleCloseNewItemModal();
      }
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  return (
    <div className="categories-wrapper">
      КАТЕГОРИИ
      <form onSubmit={handleAddCategory} className="add-category">
        Add new category
        <TextField
          label="Category Number"
          value={newCategory.categoryNumber}
          onChange={handleNewCategoryTextFieldChange}
          name="categoryNumber"
          type="number"
          className="text-field"
        />
        <TextField
          label="Name MK"
          value={newCategory.nameMk}
          onChange={handleNewCategoryTextFieldChange}
          name="nameMk"
          className="text-field"
        />
        <TextField
          label="Name EN"
          value={newCategory.nameEn}
          onChange={handleNewCategoryTextFieldChange}
          name="nameEn"
          className="text-field"
        />
        <TextField
          label="Image URL"
          value={newCategory.imageUrl}
          onChange={handleNewCategoryTextFieldChange}
          name="imageUrl"
          className="text-field"
        />
        <InputLabel id="type-label">Type</InputLabel>
        <Select
          labelId="type-label"
          value={newCategory.type}
          onChange={handleNewCategorySelectChange}
          name="type"
          className="text-field"
        >
          {categoryTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
        <InputLabel id="group-label">Group</InputLabel>
        <Select
          labelId="group-label"
          value={newCategory?.group?._id ?? ""}
          onChange={handleGroupSelectChange}
          name="group"
          className="text-field"
          displayEmpty
          fullWidth
        >
          {groups.map((group) => (
            <MenuItem key={group._id} value={group._id}>
              {group.groupName}
            </MenuItem>
          ))}
        </Select>
        <Button
          type="submit"
          disabled={
            !newCategory.categoryNumber ||
            !newCategory.nameMk ||
            !newCategory.nameEn ||
            !newCategory.imageUrl ||
            !newCategory.type
          }
        >
          Add Category
        </Button>
      </form>
      <InputLabel id="group-filter-label">Group Filter</InputLabel>
      <Select
        labelId="group-filter-label"
        value={selectedGroupFilter}
        onChange={(event) =>
          setSelectedGroupFilter(event.target.value as string)
        }
        displayEmpty
      >
        <MenuItem value="all">All</MenuItem>
        {groups.map((group) => (
          <MenuItem key={group._id} value={group._id}>
            {group.groupName}
          </MenuItem>
        ))}
      </Select>
      {groups
        .filter(
          (group) =>
            selectedGroupFilter === "all" || group._id === selectedGroupFilter
        )
        .map((group) => {
          return (
            <div
              className={`group-section ${
                group.isMainGroup ? " main-group" : ""
              }`}
            >
              {" "}
              <div className="groupName">
                <Button
                  className="groupNameButton"
                  onClick={() => handleOpenModal(group)}
                  disabled={group.isMainGroup}
                >
                  {group.groupName}
                </Button>
              </div>
              <Dialog
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Set as Main Group"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Do you want to make this group the main group?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    className="cancelGroupDialog"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="confirmGroupDialog"
                    onClick={handleSetMainGroup}
                    autoFocus
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
              {categories.map((category) => {
                return category.group?._id === group._id ? (
                  <div key={category._id} className="category-with-items">
                    <Card
                      onClick={() => handleOpen(category)}
                      sx={{
                        width: 250,
                        backgroundColor: "#632b30",
                        color: "#ffffff",
                        textAlign: "center",
                        margin: "0 auto",
                      }}
                    >
                      <CardContent className="card-content">
                        <Typography
                          variant="h5"
                          component="div"
                          className="card-text"
                        >
                          CN: {category.categoryNumber ?? 0}
                        </Typography>
                        <Typography
                          variant="h5"
                          component="div"
                          className="card-text"
                        >
                          {category.nameMk}
                        </Typography>
                        <Typography
                          variant="h5"
                          component="div"
                          className="card-text"
                        >
                          {category.nameEn}
                        </Typography>
                      </CardContent>
                    </Card>
                    <div className="the-items">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleClickOpen(category._id ?? "")}
                      >
                        Add item to {category.nameMk}
                      </Button>
                      <Dialog
                        open={openNewItemModal}
                        onClose={handleCloseNewItemModal}
                      >
                        <DialogTitle>Add New Item</DialogTitle>
                        <DialogContent>
                          <TextField
                            margin="dense"
                            name="itemNumber"
                            label="Item Number"
                            type="number"
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                          />
                          <TextField
                            autoFocus
                            margin="dense"
                            name="nameMk"
                            label="Name MK"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                          />
                          <TextField
                            autoFocus
                            margin="dense"
                            name="nameEn"
                            label="Name EN"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                          />
                          <TextField
                            margin="dense"
                            name="price"
                            label="Price"
                            type="number"
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                          />
                          <TextField
                            margin="dense"
                            name="imageUrl"
                            label="Image URL"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                          />
                          <TextField
                            margin="dense"
                            name="ingredientsMk"
                            label="Ingredients MK"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                          />
                          <TextField
                            margin="dense"
                            name="ingredientsEn"
                            label="Ingredients EN"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseNewItemModal}>
                            Cancel
                          </Button>
                          <Button onClick={handleAddItem}>Add</Button>
                        </DialogActions>
                      </Dialog>
                      {category.items &&
                        category.items.map((item) => (
                          <ItemCard
                            key={item._id}
                            item={item}
                            onItemChange={onItemChanged}
                            onItemDelete={onItemDeleted}
                          />
                        ))}
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          );
        })}
      {categories.map((category) => {
        return !category.group || category.group.groupName.length <= 0 ? (
          <div key={category._id} className="category-with-items">
            <Card
              onClick={() => handleOpen(category)}
              sx={{
                width: 250,
                backgroundColor: "#632b30",
                color: "#ffffff",
                textAlign: "center",
                margin: "0 auto",
              }}
            >
              <CardContent>
                <Typography variant="h5" component="div">
                  CN: {category.categoryNumber ?? 0}
                </Typography>
                <Typography variant="h5" component="div">
                  {category.nameMk}
                </Typography>
                <Typography variant="h5" component="div">
                  {category.nameEn}
                </Typography>
              </CardContent>
            </Card>
            <div className="the-items">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleClickOpen(category._id ?? "")}
              >
                Add item to {category.nameMk}
              </Button>
              <Dialog open={openNewItemModal} onClose={handleCloseNewItemModal}>
                <DialogTitle>Add New Item</DialogTitle>
                <DialogContent>
                  <TextField
                    margin="dense"
                    name="itemNumber"
                    label="Item Number"
                    type="number"
                    fullWidth
                    variant="standard"
                    onChange={handleInputChange}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    name="nameMk"
                    label="Name MK"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={handleInputChange}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    name="nameEn"
                    label="Name EN"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={handleInputChange}
                  />
                  <TextField
                    margin="dense"
                    name="price"
                    label="Price"
                    type="number"
                    fullWidth
                    variant="standard"
                    onChange={handleInputChange}
                  />
                  <TextField
                    margin="dense"
                    name="imageUrl"
                    label="Image URL"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={handleInputChange}
                  />
                  <TextField
                    margin="dense"
                    name="ingredientsMk"
                    label="Ingredients MK"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={handleInputChange}
                  />
                  <TextField
                    margin="dense"
                    name="ingredientsEn"
                    label="Ingredients EN"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={handleInputChange}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseNewItemModal}>Cancel</Button>
                  <Button onClick={handleAddItem}>Add</Button>
                </DialogActions>
              </Dialog>
              {category.items &&
                category.items.map((item) => (
                  <ItemCard
                    key={item._id}
                    item={item}
                    onItemChange={onItemChanged}
                    onItemDelete={onItemDeleted}
                  />
                ))}
            </div>
          </div>
        ) : null;
      })}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "500px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            height: "450px",
          }}
        >
          <Typography variant="h6" component="div">
            Edit Category
          </Typography>
          <TextField
            label="CategoryNumber"
            value={selectedCategory?.categoryNumber ?? 0}
            onChange={handleCategoryChange}
            name="categoryNumber"
            type="number"
            sx={{
              width: "100%",
            }}
          />
          <TextField
            label="Name MK"
            value={selectedCategory?.nameMk ?? ""}
            onChange={handleCategoryChange}
            name="nameMk"
            sx={{
              width: "100%",
            }}
          />
          <TextField
            label="Name EN"
            value={selectedCategory?.nameEn ?? ""}
            onChange={handleCategoryChange}
            name="nameEn"
            sx={{
              width: "100%",
            }}
          />
          <TextField
            label="Image URL"
            value={selectedCategory?.imageUrl ?? ""}
            onChange={handleCategoryChange}
            name="imageUrl"
            sx={{
              width: "100%",
            }}
          />
          <Select
            label="Group"
            value={selectedCategory?.group?._id ?? ""}
            onChange={handleCategoryChange}
            name="group"
            displayEmpty
            fullWidth
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {groups.map((group) => (
              <MenuItem key={group._id} value={group._id}>
                {group.groupName}
              </MenuItem>
            ))}
          </Select>
          <Button onClick={onCategoryChanged}>Save</Button>
          {!selectedCategory?.items ||
            (selectedCategory.items.length <= 0 && (
              <Button onClick={onCategoryDeleted}>Delete</Button>
            ))}
        </Box>
      </Modal>
    </div>
  );
};

export default CategoryAddEdit;
