import {CSSProperties} from "react";
import BounceLoader from "react-spinners/BounceLoader";

import "./Loader.scss";

const override: CSSProperties = {
  display: "absolute",
  margin: "0 auto",
  borderColor: "red",
};

interface LoaderProps {
  loading: boolean;
}

const Loader: React.FC<LoaderProps> = ({loading}) => {
  return (
    <div className="loader">
      <BounceLoader
        color={"#632b30"}
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loader;
