import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {
  Button,
  Box,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import {endpoint} from "../../endpoint/endpoint";
import "./AdminPanel.scss";
import RestaurantNumberEdit, {
  HeaderInfo,
} from "./RestaurantNumberEdit/RestaurantNumberEdit";
import CategoryAddEdit, {Category} from "./CategoryAddEdit/CategoryAddEdit";
import {IItem} from "./ItemAddEdit/ItemAddEdit";

interface DataSkeleton {
  header_info: HeaderInfo;
  categories: Category[];
  groups: IGroup[];
}

export interface IGroup {
  _id?: string;
  groupName: string;
  isMainGroup?: boolean;
}

const AdminPanel: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [data, setData] = useState<DataSkeleton | null>(null);
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newGroup, setNewGroup] = useState<IGroup>({
    groupName: "",
  });

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const StyledBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
  });

  useEffect(() => {
    const checkTokenValidity = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.get(`${endpoint()}/auth/token`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 201) {
            setIsLoggedIn(true);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    };

    checkTokenValidity();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const token = localStorage.getItem("token");
      axios
        .get(endpoint() + `/admin`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setData(response.data.info as any);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [isLoggedIn]);
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${endpoint()}/auth/login`, {
        email,
        password,
      });

      if (response.status === 201) {
        localStorage.setItem("token", response.data.token);
        setIsLoggedIn(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  };

  const goToHomepage = () => {
    navigate("/");
  };
  const onNumberChange = (header_info: HeaderInfo) => {
    setData((prevData) => ({
      ...(prevData ? prevData : {categories: [], groups: []}),
      header_info: header_info,
    }));
  };

  const onCategoryChange = (updatedCategory: Category) => {
    const updatedData = {...(data as DataSkeleton)};

    const index = updatedData.categories.findIndex(
      (category) => category._id === updatedCategory._id
    );

    if (index !== -1) {
      updatedData.categories[index] = updatedCategory;
    } else {
      updatedData.categories.push(updatedCategory);
    }

    setData(updatedData);
  };

  const onCategoryAdd = (newCategory: Category) => {
    const updatedData = {...(data as DataSkeleton)};

    updatedData.categories.push(newCategory);

    setData(updatedData);
  };

  const onGroupAdd = (newGroup: IGroup) => {
    const updatedData = {...(data as DataSkeleton)};

    updatedData.groups.push(newGroup);

    setData(updatedData);
  };

  const onGroupChange = (editedGroup: IGroup) => {
    const updatedData = {...(data as DataSkeleton)};

    const groupIndex = updatedData.groups.findIndex(
      (group) => group._id === editedGroup._id
    );

    if (groupIndex !== -1) {
      updatedData.groups[groupIndex] = editedGroup;
      updatedData.groups.forEach((group) => {
        if (group.isMainGroup && group._id !== editedGroup._id)
          group.isMainGroup = false;
      });
    }

    setData(updatedData);
  };

  const onItemChange = (updatedItem: IItem) => {
    const updatedData = {...(data as DataSkeleton)};

    const category = updatedData.categories.find(
      (category) => category._id === updatedItem.categoryId
    );

    if (category) {
      const itemIndex = category.items?.findIndex(
        (item) => item._id === updatedItem._id
      );

      if (itemIndex !== -1 && category.items) {
        category.items[itemIndex ?? 0] = updatedItem;
      } else if (category.items) {
        category.items.push(updatedItem);
      }
    }

    setData(updatedData);
  };

  const onCategoryDelete = (deletedCategory: Category) => {
    const updatedData = {...(data as DataSkeleton)};

    const index = updatedData.categories.findIndex(
      (category) => category._id === deletedCategory._id
    );

    if (index !== -1) {
      updatedData.categories.splice(index, 1);
    }

    setData(updatedData);
  };

  const onItemDelete = (deletedItem: IItem) => {
    const updatedData = {...(data as DataSkeleton)};

    const category = updatedData.categories.find(
      (category) => category._id === deletedItem.categoryId
    );

    if (category && category.items) {
      const itemIndex = category.items.findIndex(
        (item) => item._id === deletedItem._id
      );

      if (itemIndex !== -1) {
        category.items.splice(itemIndex, 1);
      }
    }

    setData(updatedData);
  };

  const handleInputChange = (event: {target: {name: any; value: any}}) => {
    setNewGroup({...newGroup, [event.target.name]: event.target.value});
  };
  const handleSaveGroup = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endpoint()}/admin/save/group`,
        {newGroup},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        onGroupAdd(response.data.newGroup);
        setNewGroup({
          groupName: "",
        });
        toggleDialog();
      } else {
        console.error("Error saving a category with that name already exists!");
      }
    } catch (e) {
      console.error("ERROR: ", e);
    }
  };

  if (isLoggedIn && data) {
    return (
      <div>
        <StyledBox>
          <Button onClick={goToHomepage}>⬅️ Go to homepage</Button>
          <Button onClick={handleLogout}>LogOut</Button>
        </StyledBox>
        <RestaurantNumberEdit
          header_info={data.header_info as HeaderInfo}
          onNumberChange={onNumberChange}
        />
        <div className="addGroupButton">
          <Button variant="outlined" color="primary" onClick={toggleDialog}>
            Add a new Group
          </Button>

          <Dialog open={isDialogOpen} onClose={toggleDialog}>
            <DialogTitle>Add Group</DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                name="groupName"
                label="Group Name"
                fullWidth
                variant="standard"
                onChange={handleInputChange}
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={newGroup.groupName.length <= 0}
                onClick={handleSaveGroup}
              >
                Save
              </Button>
              <Button onClick={toggleDialog}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </div>

        <CategoryAddEdit
          groups={data.groups}
          categories={data.categories}
          onCategoryChange={onCategoryChange}
          onCategoryAdd={onCategoryAdd}
          onCategoryDelete={onCategoryDelete}
          onItemChange={onItemChange}
          onItemDelete={onItemDelete}
          onGroupChange={onGroupChange}
        />
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <label>
        Email:
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </label>
      <label>
        Password:
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </label>
      <button type="submit">Submit</button>
    </form>
  );
};

export default AdminPanel;
