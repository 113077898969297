import React, {useState, useEffect} from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import {Box} from "@mui/system";
import {motion, AnimatePresence} from "framer-motion";

import "./Menu.scss";
import Categories from "./Categories/Categories";
import {endpoint} from "../../endpoint/endpoint";
import MyOrder, {MyOrderInterface} from "./MyOrder/MyOrder";
import logo from "../../assets/logo.png";
import Loader from "../Loader/Loader";
import {Category} from "../AdminComponent/CategoryAddEdit/CategoryAddEdit";
import {IItem} from "../AdminComponent/ItemAddEdit/ItemAddEdit";

export const categoriesFood = [
  "salads",
  "cold-appetizer",
  "hot-appetizer",
  "fish",
  "soups",
  "additions",
  "bbq",
  "pizza-pastramajlija",
  "pre-order-foods",
  "desserts",
  "nuts",
  "food",
];

export const categoriesDrinks = [
  "coffee",
  "non-alcoholic-drinks",
  "alcoholic-drinks",
  "beer",
  "wines",
  "drinks",
];

const sectionButtonCSS = (choice: string, type: string) => {
  return {
    borderRadius: "15px",
    color: choice === type ? "#fff" : "#632b30",
    backgroundColor: choice === type ? "#632b30" : "#00000000",
    borderColor: "#632b30",
    "&:hover": {
      backgroundColor: "#632b30",
      color: "#fff",
    },
  };
};
const buttonCSS = (lang: string, type: string) => {
  return {
    minWidth: "30px",
    maxWidth: "30px",
    padding: "0px",
    borderRadius: "10px",
    lineHeight: "24px",
    color: lang === type ? "#fff" : "#632b30",
    backgroundColor: lang === type ? "#632b30" : "#00000000",
    borderColor: "#632b30",
    "&:hover": {
      backgroundColor: "#632b30",
      color: "#fff",
    },
  };
};

const Menu = () => {
  const [loadAttempt, setLoadAttempt] = useState(0);
  const [categories, setCategories] = useState<Category[]>([]);
  const [numberToCall, setNumberToCall] = useState(null);
  const [myOrder, setMyOrder] = useState<MyOrderInterface>({});
  const [isLoading, setIsLoading] = useState(true);
  const [lang, setLang] = useState(localStorage.getItem("language") || "mk");
  const [menuToggle, setMenuToggle] = useState("food");
  const [toggleDisabled, setToggleDisabled] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollRef = React.useRef<HTMLDivElement>(null);

  const handleImageError = () => {
    setLoadAttempt(loadAttempt + 1);
  };

  useEffect(() => {
    if (!scrollRef.current) return;

    const handleScroll = () => {
      const isScrolled =
        scrollRef.current?.scrollTop &&
        scrollRef.current.scrollTop > 150 &&
        scrollRef.current?.scrollHeight > 900;
      setIsScrolled(!!isScrolled);
    };

    const node = scrollRef.current;
    if (node) {
      node.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (node) {
        node.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollRef, isLoading]);

  useEffect(() => {
    const lang = localStorage.getItem("language");
    setMenuToggle("food");
    if (!lang) {
      localStorage.setItem("language", "mk");
      setLang("mk");
    } else {
      setLang(lang);
    }
  }, []);
  useEffect(() => {
    axios
      .get(`${endpoint()}/`)
      .then((response: any) => {
        setCategories(
          response.data.info.categoriesInfo.sort((a: Category, b: Category) => {
            let aValue = a.categoryNumber == null ? Infinity : a.categoryNumber;
            let bValue = b.categoryNumber == null ? Infinity : b.categoryNumber;
            return aValue - bValue;
          })
        );
        setNumberToCall(response.data.info.headerInfo.restaurantNumber);
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.error("There was an error!", error);
        setIsLoading(false);
      });
  }, []);

  const onItemsLoaded = (items: IItem[], categoryId: string) => {
    categories.map((category) => {
      if (category._id === categoryId) {
        category.items = items;
        return category;
      } else {
        return category;
      }
    });
  };

  const addToOrder = (item: IItem) => {
    setMyOrder((prevOrder: MyOrderInterface) => {
      const newOrder = {...prevOrder};
      const itemId = item._id;

      if (itemId) {
        if (newOrder[itemId]) {
          newOrder[itemId].count += 1;
        } else {
          newOrder[itemId] = {item, count: 1};
        }
      }

      return newOrder;
    });
  };

  const removeFromOrder = (item: IItem) => {
    setMyOrder((prevOrder: MyOrderInterface) => {
      const newOrder = {...prevOrder};
      const itemId = item._id;

      if (itemId && newOrder[itemId]) {
        delete newOrder[itemId];
      }

      return newOrder;
    });
  };

  const onOrderChange = (itemId: string, newCount: number) => {
    setMyOrder((prevOrder) => {
      const newOrder = {...prevOrder};

      if (newCount > 0) {
        newOrder[itemId].count = newCount;
      } else {
        delete newOrder[itemId];
      }

      return newOrder;
    });
  };

  const handleSectionChange = (section: string) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
    setMenuToggle(section);
    setToggleDisabled(true);
    setTimeout(() => {
      setToggleDisabled(false);
    }, 300);
  };

  return (
    <div className={`menu${isScrolled ? " compact" : ""}`}>
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <>
          <h2 className="name">
            <img
              src={logo}
              onError={handleImageError}
              alt="description"
              className="logo"
            />
          </h2>
          <Box className="language-picker">
            <Button
              variant={lang === "mk" ? "contained" : "outlined"}
              onClick={() => {
                setLang("mk");
                localStorage.setItem("language", "mk");
              }}
              sx={buttonCSS(lang, "mk")}
            >
              <img
                src="https://cdn.britannica.com/08/6208-004-6D4D0D62/Flag-North-Macedonia.jpg"
                alt="MK"
                style={{maxWidth: "100%", aspectRatio: "16/9"}}
              />
            </Button>
            <Button
              variant={lang === "en" ? "contained" : "outlined"}
              onClick={() => {
                setLang("en");
                localStorage.setItem("language", "en");
              }}
              sx={buttonCSS(lang, "en")}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg"
                alt="EN"
                style={{maxWidth: "100%", aspectRatio: "16/9"}}
              />
            </Button>
          </Box>
          <h3 className="info">
            {lang === "mk" ? (
              <span>Број за нарачка </span>
            ) : (
              <span>Order number </span>
            )}
            <a href={"tel:" + numberToCall} className="number-link">
              {numberToCall}
            </a>
          </h3>
          <h1 className="name-2">
            {" "}
            {lang === "mk" ? <span>МЕНИ</span> : <span>MENU</span>}
          </h1>
          <Box className="menu-picker">
            <Button
              variant={menuToggle === "food" ? "contained" : "outlined"}
              onClick={() => {
                handleSectionChange("food");
              }}
              sx={sectionButtonCSS(menuToggle, "food")}
              disabled={toggleDisabled}
            >
              {lang === "mk" ? <span>ХРАНА</span> : <span>FOOD</span>}
            </Button>
            <Button
              variant={menuToggle === "drinks" ? "contained" : "outlined"}
              onClick={() => {
                handleSectionChange("drinks");
              }}
              sx={sectionButtonCSS(menuToggle, "drinks")}
              disabled={toggleDisabled}
            >
              {lang === "mk" ? <span>ПИЈАЛОЦИ</span> : <span>DRINKS</span>}
            </Button>
          </Box>
          <Box className="slide-box" ref={scrollRef}>
            <AnimatePresence mode="wait">
              <motion.div
                key={menuToggle}
                initial={{x: 100, opacity: 0}}
                animate={{x: 0, opacity: 1}}
                exit={{x: -100, opacity: 0}}
                transition={{
                  type: "tween",
                  stiffness: 100,
                  damping: 50,
                  duration: 0.2,
                }}
              >
                <Categories
                  categories={categories.filter((category) => {
                    if (menuToggle === "food") {
                      return categoriesFood.some(
                        (value) => value === category.type
                      );
                    } else {
                      return categoriesDrinks.some(
                        (value) => value === category.type
                      );
                    }
                  })}
                  myOrder={myOrder}
                  lang={lang}
                  onItemsLoaded={onItemsLoaded}
                  onCategoryAddToOrder={addToOrder}
                  onCategoryRemoveFromOrder={removeFromOrder}
                />
              </motion.div>
            </AnimatePresence>
          </Box>
          <MyOrder order={myOrder} lang={lang} onOrderChange={onOrderChange} />
        </>
      )}
    </div>
  );
};

export default Menu;
