import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import placeholder from "../../../../assets/placeholder.jpeg";
import "./Item.scss";
import {IItem} from "../../../AdminComponent/ItemAddEdit/ItemAddEdit";

interface ItemProps {
  item: IItem;
  lang: string;
  inBasket: boolean;
  onAddToOrder: (item: IItem) => void;
  onRemoveFromOrder: (item: IItem) => void;
}

const Item: React.FC<ItemProps> = ({
  item,
  lang,
  inBasket,
  onAddToOrder,
  onRemoveFromOrder,
}) => {
  const [showIngredients, setShowIngredients] = useState(false);

  const toggleIngredients = () => {
    setShowIngredients(!showIngredients);
  };

  const handleAddToOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onAddToOrder(item);
  };

  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = placeholder;
  };

  return (
    <div className="item-card" onClick={toggleIngredients}>
      {inBasket ? (
        <IconButton
          className="remove-button"
          onClick={(event) => {
            event.stopPropagation();
            onRemoveFromOrder(item);
          }}
          style={{
            position: "absolute",
            top: "3px",
            right: "3px",
            color: "white",
            background: "red",
          }}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      ) : (
        <IconButton
          className="add-button"
          onClick={(event) => {
            event.stopPropagation();
            onAddToOrder(item);
          }}
          style={{
            position: "absolute",
            top: "3px",
            right: "3px",
            color: "white",
            background: "green",
          }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      )}
      <img
        src={item.imageUrl}
        onError={handleImageError}
        alt="drinks"
        className="item-img"
      />
      <div className="item-text">
        {lang === "mk" ? <h3>{item.nameMk}</h3> : <h3>{item.nameEn}</h3>}
        <p>{item.price} ден.</p>
      </div>
      <p className={`item-ingredients ${showIngredients ? "show" : ""}`}>
        {lang === "mk" ? (
          <span>Состојки: {item.ingredientsMk}</span>
        ) : (
          <span>Ingredients: {item.ingredientsEn}</span>
        )}
      </p>
    </div>
  );
};

export default Item;
