import {useState} from "react";
import {
  Card,
  CardContent,
  Typography,
  Modal,
  TextField,
  Button,
  Box,
} from "@mui/material";
import axios from "axios";
import {endpoint} from "../../../endpoint/endpoint";

export interface IItem {
  _id?: string;
  itemNumber?: number;
  categoryId?: string;
  nameMk: string;
  nameEn: string;
  imageUrl: string;
  price: number;
  ingredientsMk: string;
  ingredientsEn: string;
}

interface ItemCardProps {
  item: IItem;
  onItemChange: (item: IItem) => void;
  onItemDelete: (item: IItem) => void;
}

const ItemCard: React.FC<ItemCardProps> = ({
  item,
  onItemChange,
  onItemDelete,
}) => {
  const [open, setOpen] = useState(false);
  const [editedItem, setEditedItem] = useState<IItem>(item);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedItem({...editedItem, [event.target.name]: event.target.value});
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endpoint()}/admin/edit/item`,
        {editedItem},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        onItemChange(response.data.updatedItem);
        handleClose();
      } else {
        console.error("The item update failed! Check the data you enter!!!");
        handleClose();
      }
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${endpoint()}/admin/delete/item/${editedItem._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 && editedItem._id) {
        onItemDelete(editedItem);
        handleClose();
      } else {
        console.error("The item update failed! Check the data you enter!!!");
        handleClose();
      }
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  return (
    <div>
      <Card
        onClick={handleOpen}
        sx={{
          width: 200,
          backgroundColor: "#632b3099",
          color: "#000000",
          textAlign: "center",
        }}
      >
        <CardContent className="card-content">
          <Typography variant="h5" component="div" className="card-text">
            IN:{item.itemNumber ?? 0}
          </Typography>
          <Typography variant="h5" component="div" className="card-text">
            {item.nameMk}
          </Typography>
          <Typography variant="h5" component="div" className="card-text">
            {item.nameEn}
          </Typography>
        </CardContent>
      </Card>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "500px",
            bgcolor: "white",
            boxShadow: 24,
            p: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            height: "600px",
          }}
        >
          Edit Item
          <TextField
            label="Item Number"
            value={editedItem.itemNumber ?? 0}
            onChange={handleItemChange}
            name="itemNumber"
            type="number"
          />
          <TextField
            label="Name MK"
            value={editedItem.nameMk}
            onChange={handleItemChange}
            name="nameMk"
          />
          <TextField
            label="Name EN"
            value={editedItem.nameEn}
            onChange={handleItemChange}
            name="nameEn"
          />
          <TextField
            label="Price"
            value={editedItem.price}
            onChange={handleItemChange}
            name="price"
            type="number"
          />
          <TextField
            label="Ingredients MK"
            value={editedItem.ingredientsMk}
            onChange={handleItemChange}
            name="ingredientsMk"
          />
          <TextField
            label="Ingredients EN"
            value={editedItem.ingredientsEn}
            onChange={handleItemChange}
            name="ingredientsEn"
          />
          <TextField
            label="Image URL"
            value={editedItem.imageUrl}
            onChange={handleItemChange}
            name="imageUrl"
          />
          <Button onClick={handleSave} sx={{border: "2px solid lime"}}>
            Save
          </Button>
          <Button
            onClick={handleDelete}
            sx={{
              background: "red",
              color: "white",
            }}
          >
            Delete
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ItemCard;
