import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import CloseCircleOutline from "@mui/icons-material/CloseTwoTone";
import "./MyOrder.scss";
import {IItem} from "../../AdminComponent/ItemAddEdit/ItemAddEdit";

interface OrderItem {
  item: IItem;
  count: number;
}

export interface MyOrderInterface {
  [key: string]: OrderItem;
}

interface MyOrderProps {
  order: MyOrderInterface;
  lang: string;
  onOrderChange: (itemId: string, newCount: number) => void;
}

const MyOrder = ({order, lang, onOrderChange}: MyOrderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [total, setTotal] = useState(0);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let newTotal = 0;
    Object.values(order).forEach((orderItem) => {
      newTotal += orderItem.item.price * orderItem.count;
    });
    setTotal(newTotal);
  }, [order]);

  const handleCountChange = (
    event: React.MouseEvent<HTMLButtonElement>,
    itemId: string,
    delta: number
  ) => {
    const newCount = Math.max(
      0,
      Math.min(20, (order[itemId]?.count || 0) + delta)
    );
    onOrderChange(itemId, newCount);
  };

  return (
    <div className={`my-order ${isOpen ? "open" : ""}`}>
      {isOpen ? (
        <div className="order-details">
          <div className="order-header" onClick={toggleOpen}>
            <h2>
              {lang === "en" ? (
                <span>Order planner </span>
              ) : (
                <span>Планер за нарачка </span>
              )}
              <IconButton>
                <CloseCircleOutline />
              </IconButton>
            </h2>{" "}
            {lang === "en" ? (
              <h3>Total: {total} ден.</h3>
            ) : (
              <h3>Вкупно: {total} ден.</h3>
            )}
          </div>
          <div className="order-list">
            {Object.entries(order).map(([itemId, orderItem]) => (
              <div key={itemId} className="order-item">
                {lang === "en" ? (
                  <span>{orderItem.item.nameEn}</span>
                ) : (
                  <span>{orderItem.item.nameMk}</span>
                )}
                {lang === "en" ? (
                  <span>Price: {orderItem.item.price * orderItem.count}</span>
                ) : (
                  <span>Цена: {orderItem.item.price * orderItem.count}</span>
                )}
                <div>
                  <IconButton
                    onClick={(event) => handleCountChange(event, itemId, -1)}
                    style={{
                      color: "white",
                    }}
                  >
                    <RemoveCircleOutline />
                  </IconButton>
                  <span>{orderItem.count}</span>
                  <IconButton
                    onClick={(event) => handleCountChange(event, itemId, 1)}
                    style={{
                      color: "white",
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <button className="my-order-button" onClick={toggleOpen}>
          {lang === "en" ? (
            <span>Order planner</span>
          ) : (
            <span>Планер за нарачка</span>
          )}
        </button>
      )}
    </div>
  );
};

export default MyOrder;
